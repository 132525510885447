.e-control.e-dialog.e-lib.e-edit-dialog.e-dlg-modal.e-popup.e-popup-open {
    width: 45% !important;
}

.pseColoring {
    background-color: #006671;
    height: 10px
}

button#submit {
    background-color: #197ee3;
    border-radius: 5px;
    padding: 0px 6px 0px 9px;
    font-size: 16px;
    line-height: 1.5em;
}

button#submit:hover {
    background-color: #004A94;
}

.e-toolbar-items > .e-toolbar-left > .e-toolbar-item > button[id$="add"] {
    background-color: #699B4E;
    border-radius: 5px;
    padding: 0px 6px 0px 9px;
    font-size: 16px;
    line-height: 1.5em;
}

.e-toolbar-items > .e-toolbar-left > .e-toolbar-item > button[id$="delete"] {
    background-color: #C41F3D;
    border-radius: 5px;
    padding: 0px 6px 0px 9px;
    font-size: 16px;
    line-height: 1.5em;
}

.e-toolbar-items > .e-toolbar-left > .e-toolbar-item > button[id$="edit"] {
    background-color: #ED9F1B;
    border-radius: 5px;
    padding: 0px 6px 0px 9px;
    font-size: 16px;
    line-height: 1.5em;
}

.e-toolbar-items > .e-toolbar-left > .e-toolbar-item > button[aria-disabled="true"] { background-color: #717171; }

button#submit > .e-btn-icon,
button#submit > .e-tbar-btn-text,
.e-toolbar-items > .e-toolbar-left > .e-toolbar-item > button[id$="add"] > .e-btn-icon,
.e-toolbar-items > .e-toolbar-left > .e-toolbar-item > button[id$="add"] > .e-tbar-btn-text,
.e-toolbar-items > .e-toolbar-left > .e-toolbar-item > button[id$="delete"] > .e-btn-icon,
.e-toolbar-items > .e-toolbar-left > .e-toolbar-item > button[id$="delete"] > .e-tbar-btn-text,
.e-toolbar-items > .e-toolbar-left > .e-toolbar-item > button[id$="edit"] > .e-btn-icon,
.e-toolbar-items > .e-toolbar-left > .e-toolbar-item > button[id$="edit"] > .e-tbar-btn-text {
    color: white
}
